"use client";

import React from "react";
import Link from "next/link";
import useBreakpoint from "~/hooks/useBreakpoint";
import { cn } from "~/utils/cn";

import { NavbarTemplateLogoOnly } from "./components";
import { NavbarButtons } from "./components/navbar-buttons";
import { useSession } from "next-auth/react";
import ScalisLogo from "../logo/scalis-logo";
import { SearchMenu } from "../search-menu/search-menu";
import { useNavLinks } from "~/scalis-components/team/navbar-variations/default/navbar-default.hooks";
import { usePathname, useRouter } from "next/navigation";
import { Routes } from "~/src/app/_constants/routes";
import useClientSubdomain from "~/hooks/use-client-subdomain";
import useNavbarType, { NavbarType } from "~/hooks/use-navbar-type";
import { MobileNav } from "~/scalis-components/landing/components/navbar/mobile-nav";

export interface NavBarTemplateProps {
  isLogoOnly?: boolean;
};

const NavbarTemplate = ({
  isLogoOnly,
}: NavBarTemplateProps) => {
  const navLinks = useNavLinks();
  const { lg, md } = useBreakpoint();
  const { status, data } = useSession();
  const pathname = usePathname();
  const subdomain = useClientSubdomain();
  const navbarType = useNavbarType();
  const router = useRouter();

  const isSignedIn = status === "authenticated" && data.user.emailVerified;
  const isLoading = status === "loading";

  if (isLogoOnly || isLoading) {
    return (
      <NavbarTemplateLogoOnly isLoggedOut={!isSignedIn} isLoading={isLoading} />
    );
  }

  let breakpoint = true;
  if (subdomain) {
    breakpoint = md;
  }

  const handleOnSubmit = (data: any) => {
    router.push(`/jobs?search=${encodeURIComponent(data.search)}`);
  };

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between border-b border-neutral-10 px-4 py-[0.375rem]",
        navbarType === NavbarType.COMPANY_WORKSPACE ? "bg-brand-primary-light-00" : "bg-white",
      )}
    >
      <div className="flex w-full items-center gap-4 lg:w-auto">
        <MobileNav />
        <ScalisLogo />
        {isSignedIn && pathname !== Routes.jobs_view && !pathname?.includes('/job') && breakpoint && (
          <div className="w-full lg:w-[15.625rem]">
            <SearchMenu onSubmit={handleOnSubmit} />
          </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        <div className="flex gap-2">
          {lg &&
            navLinks.map(({ label, href }, index) => (
              <Link
                role="link"
                aria-label={label}
                className={cn(
                  "whitespace-nowrap rounded-lg p-2 text-sm/6 font-medium text-neutral-primary hover:bg-brand-primary-light-10 active:bg-button-brand-primary-light-hover",
                )}
                href={href}
                key={`navbar-link-index-${index}`}
              >
                {label}
              </Link>
            ))}
        </div>

        {!isLoading && (
          <NavbarButtons />
        )}
      </div>
    </div>
  );
};

export default NavbarTemplate;